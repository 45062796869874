.cost-calculator {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;
  color: #212b36;
}

h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 8px;
}

h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.description {
  color: #637381;
  margin-bottom: 24px;
}

.shopify-plan-selector, .monthly-sales-volume, .additional-features, .apps-selector {
  margin-bottom: 24px;
}

.plan-options, .volume-display, .feature-item, .app-item {
  background-color: #ffffff;
  border: 1px solid #dfe3e8;
  border-radius: 3px;
  padding: 16px;
}

.plan-option, .feature-item, .app-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.plan-option {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.plan-option input[type="radio"] {
  margin-right: 12px;
}

.plan-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.plan-name {
  font-weight: 500;
}

.plan-price {
  color: #637381;
  font-size: 14px;
}

.volume-amount, .feature-cost, .app-cost {
  color: #637381;
}

.edit-button {
  background-color: transparent;
  border: none;
  color: #006fbb;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
}

.calculate-button {
  background-color: #008060;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 24px;
  width: 100%;
}

.total-cost {
  margin-top: 24px;
  border-top: 1px solid #dfe3e8;
  padding-top: 24px;
}

.cost-label {
  color: #637381;
  margin-bottom: 8px;
}

.cost-amount {
  font-size: 24px;
  font-weight: 600;
  color: #212b36;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dfe3e8;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #008060;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
