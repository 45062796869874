.feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.feature-info {
  display: flex;
  align-items: center;
}

.feature-name {
  margin-right: 10px; /* Changed from 15px to 10px */
}

.feature-cost {
  margin-right: 15px;
}

/* ... (keep other existing styles) ... */
