.apps-selector {
  margin-bottom: 24px;
}

.app-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.app-item:last-child {
  border-bottom: none;
}

.app-info {
  display: flex;
  align-items: center;
}

.app-name {
  margin-right: 10px; /* Changed from 15px to 10px */
}

.app-cost {
  margin-right: 15px;
  color: #637381;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #008060;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
