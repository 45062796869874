.thanks-message {
  margin-top: 20px;
  font-size: 1em;
}

.thanks-message a {
  color: #4CAF50;
  text-decoration: none;
}

.thanks-message a:hover {
  text-decoration: underline;
}
