.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #212b36;
  padding: 0 20px;
  height: 56px;
  border-bottom: 1px solid #dfe3e8;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo {
  font-weight: bold;
  font-size: 1.5rem;
  background: linear-gradient(45deg, #1fd3c9, #3d7dd7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.5rem;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.help-button-container {
  position: relative;
  margin-right: 16px;
}

.help-button {
  background-color: transparent;
  color: #212b36;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;  /* Add this line to remove the underline */
}

.shopify-expert-services-button {
  background-color: #008060;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
}

.shopify-expert-services-button:hover {
  background-color: #006e52;
}

.tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateX(0);
  background-color: #212b36;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  width: 200px;
  text-align: left;
  z-index: 1000;
  margin-top: 8px;
}

.tooltip::before {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #212b36 transparent;
}

@media (max-width: 768px) {
  .tooltip {
    right: -100px;
    width: 180px;
  }

  .tooltip::before {
    right: 105px;
  }
}

.navbar-item {
  color: #333;
  text-decoration: none;
  margin-left: 20px;
  font-weight: 500;
}

.navbar-item:hover {
  color: #1fd3c9;
}
